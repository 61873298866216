
<template>
	<div>
		<v-progress-linear :indeterminate="true" v-if="loading"></v-progress-linear>
		<v-container v-else class="my-2">
<h1 class="my-4"> {{$store.getters.language.data.off_day_requests.title}}</h1>
			<v-divider class="mt-8"></v-divider>
            
			<v-layout row wrap mt-5>

				<v-flex xs12>
					<v-text-field v-model="search" :label="$store.getters.language.data.extra.search" append-icon="mdi-magnify" outlined></v-text-field>
					<h3 class="my-4"> {{$store.getters.language.data.off_day_requests.update_btn_view}}</h3>

					<v-card>
						<v-card-text>
							<v-data-table :headers="headers" v-model="selected_rows" :search="search" :items="rows" class="elevation-0" item-key="off_day_request_id">
								<template v-slot:[`item.off_day_request_status`]="{ item }">
                                    <v-chip color="success" v-if="item.off_day_request_status == 'viewing'">
                                        {{item.off_day_request_status}} <v-icon>mdi-check </v-icon>
                                    </v-chip>
                                    <v-chip color="warning" v-else-if="item.off_day_request_status == 'pending'">
                                        {{item.off_day_request_status}} <v-icon>mdi-clock </v-icon>
                                    </v-chip>
                                </template>

                                <template v-slot:[`item.off_day_request_id`]="{ item }">
									<div v-if="user.user_id != 17">
										<v-btn icon :to="'/off_day_requests-list/'+item.off_day_request_id" color="teal" class="mx-1">
											<v-icon> mdi-eye </v-icon>
										</v-btn>
										<!-- <v-btn color="error" icon class="mx-1" @click="selectOffDayRequests(item)">
											<v-icon> mdi-delete-outline </v-icon>
										</v-btn> -->
									</div>
								</template>
								<template v-slot:[`item.off_day_request_date`]="{ item }">
									{{new Date(item.off_day_request_date).toISOString().slice(0, 10).replace('T', ' ')}}
								</template>
								<template v-slot:[`item.off_day_request_insert_date`]="{ item }">
									<!-- {{new Date(item.off_day_request_insert_date).toISOString().slice(0, 19).replace('T', ' ')}} -->
                                                       {{new Date(new Date(item.off_day_request_insert_date).setHours(new Date(item.off_day_request_insert_date).getHours() - 4 )).toISOString().slice(0, 19).replace('T', ' ')}}
								</template>

							</v-data-table>
						</v-card-text>

						<v-card-actions v-if="selected_rows.length > 0">
							<v-btn color="error" @click="deleteOffDayRequestsList">{{$store.getters.language.data.off_day_requests.delete_list_btn}}</v-btn>
						</v-card-actions>

					</v-card>
				</v-flex>
			</v-layout>
			<v-layout row wrap mt-5>
				<v-flex xs12>
					<h3 class="my-4"> {{$store.getters.language.data.off_day_requests.title}}</h3>
					<!-- <v-text-field v-model="search" :label="$store.getters.language.data.extra.search" append-icon="mdi-magnify" outlined></v-text-field> -->
					<v-card>
						<v-card-text>
							<v-data-table :headers="headers" v-model="selected_rows" :search="search" :items="rowsAll" class="elevation-0" item-key="off_day_request_id">
								<template v-slot:[`item.off_day_request_id`]="{ item }">
									<div v-if="user.user_id != 17">
										<v-btn icon :to="'/off_day_requests-list/'+item.off_day_request_id" color="teal" class="mx-1">
											<v-icon> mdi-eye </v-icon>
										</v-btn>
										<!-- <v-btn color="error" icon class="mx-1" @click="selectOffDayRequests(item)">
											<v-icon> mdi-delete-outline </v-icon>
										</v-btn> -->
									</div>
								</template>
								<template v-slot:[`item.off_day_request_date`]="{ item }">
									{{new Date(item.off_day_request_date).toISOString().slice(0, 10).replace('T', ' ')}}
								</template>
								<template v-slot:[`item.off_day_request_insert_date`]="{ item }">
									<!-- {{new Date(item.off_day_request_insert_date).toISOString().slice(0, 19).replace('T', ' ')}} -->                   {{new Date(new Date(item.off_day_request_insert_date).setHours(new Date(item.off_day_request_insert_date).getHours() - 4 )).toISOString().slice(0, 19).replace('T', ' ')}}
								</template>

							</v-data-table>
						</v-card-text>

						<v-card-actions v-if="selected_rows.length > 0">
							<v-btn color="error" @click="deleteOffDayRequestsList">{{$store.getters.language.data.off_day_requests.delete_list_btn}}</v-btn>
						</v-card-actions>

					</v-card>
				</v-flex>
			</v-layout>
			<v-layout row wrap mt-5>
				<v-flex xs12>
					<h3 class="my-4"> Old Rows</h3>
					<!-- <v-text-field v-model="search" :label="$store.getters.language.data.extra.search" append-icon="mdi-magnify" outlined></v-text-field> -->
					<v-card>
						<v-card-text>
							<v-data-table :headers="headers" v-model="selected_rows" :search="search" :items="old_rows" class="elevation-0" item-key="off_day_request_id">
								<template v-slot:[`item.off_day_request_status`]="{ item }">
                                    <v-chip color="warning" v-if="item.off_day_request_status == 'viewing'">
                                        {{item.off_day_request_status}} <v-icon>mdi-check </v-icon>
                                    </v-chip>
                                    <v-chip color="warning" v-else-if="item.off_day_request_status == 'pending'">
                                        {{item.off_day_request_status}} <v-icon>mdi-clock </v-icon>
                                    </v-chip>
                                    <v-chip color="error" v-else-if="item.off_day_request_status == 'declined'">
                                        {{item.off_day_request_status}} <v-icon>mdi-close </v-icon>
                                    </v-chip>
                                    <v-chip color="success" v-else-if="item.off_day_request_status == 'approved'">
                                        {{item.off_day_request_status}} <v-icon>mdi-check </v-icon>
                                    </v-chip>
                                </template>
                                <template v-slot:[`item.off_day_request_id`]="{ item }">
									<div v-if="user.user_id != 17">
										<v-btn icon :to="'/off_day_requests-list/'+item.off_day_request_id" color="teal" class="mx-1">
											<v-icon> mdi-eye </v-icon>
										</v-btn>
										<!-- <v-btn color="error" icon class="mx-1" @click="selectOffDayRequests(item)">
											<v-icon> mdi-delete-outline </v-icon>
										</v-btn> -->
									</div>
								</template>
								<template v-slot:[`item.off_day_request_date`]="{ item }">
									{{new Date(item.off_day_request_date).toISOString().slice(0, 10).replace('T', ' ')}}
								</template>
								<template v-slot:[`item.off_day_request_insert_date`]="{ item }">
									<!-- {{new Date(item.off_day_request_insert_date).toISOString().slice(0, 19).replace('T', ' ')}} -->
                                    {{new Date(new Date(item.off_day_request_insert_date).setHours(new Date(item.off_day_request_insert_date).getHours() - 4 )).toISOString().slice(0, 19).replace('T', ' ')}}
								</template>

							</v-data-table>
						</v-card-text>

						<v-card-actions v-if="selected_rows.length > 0">
							<v-btn color="error" @click="deleteOffDayRequestsList">{{$store.getters.language.data.off_day_requests.delete_list_btn}}</v-btn>
						</v-card-actions>

					</v-card>
				</v-flex>
			</v-layout>
		</v-container>
		<v-dialog v-model="delete_dialog" persistent max-width="400">
			<v-card>
				<v-card-title>
					{{$store.getters.language.data.off_day_requests.delete_question}}
				</v-card-title>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn text class="elevation-0" @click="delete_dialog = false">
						{{$store.getters.language.data.off_day_requests.cancel_btn}}
					</v-btn>
					<v-btn color="error" class="elevation-0" @click="deleteOffDayRequests(selected_off_day_requests.off_day_request_id)">
						{{$store.getters.language.data.off_day_requests.yes_btn}}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-snackbar v-model="snackbar.value" :color="snackbar.color">
			{{snackbar.text}}
		</v-snackbar>
	</div>
</template>
<script>
	import requests from '../../requests/off_day_requests.request.js'
	export default {
		data() {
			return {
				off_day_requests: {},
				search: '',
				loading: true,
				loading_btn: false,
				selected_rows: [],
				snackbar: {
					value: false,
					text: '',
					color: ''
				},
				rows: [],
				rowsAll: [],
                old_rows: [],
				selected_off_day_requests: {},
				delete_dialog: false,
				headers: [

					{
						text: this.$store.getters.language.data.employees.employee_name,
						align: 'start',
						sortable: true,
						value: 'employee_name',
					},
					{
						text: this.$store.getters.language.data.positions.position_name,
						align: 'start',
						sortable: true,
						value: 'position_name',
					},
					{
						text: this.$store.getters.language.data.off_day_requests.off_day_request_date,
						align: 'start',
						sortable: true,
						value: 'off_day_request_date',
					},

					{
						text: this.$store.getters.language.data.off_day_requests.off_day_request_amount,
						align: 'start',
						sortable: true,
						value: 'off_day_request_amount',
					},

					{
						text: this.$store.getters.language.data.off_day_requests.off_day_request_type,
						align: 'start',
						sortable: true,
						value: 'off_day_request_type',
					},
					{
						text: this.$store.getters.language.data.off_day_requests.off_day_request_note,
						align: 'start',
						sortable: true,
						value: 'off_day_request_note',
					},

					{
						text: this.$store.getters.language.data.off_day_requests.off_day_request_status,
						align: 'start',
						sortable: true,
						value: 'off_day_request_status',
					},
					{
						text: this.$store.getters.language.data.off_day_requests.off_day_request_insert_date,
						align: 'start',
						sortable: true,
						value: 'off_day_request_insert_date',
					},
					// {
					// 	text: this.$store.getters.language.data.users.user_id,
					// 	align: 'start',
					// 	sortable: true,
					// 	value: 'user_id',
					// },

					{
						text: this.$store.getters.language.data.actions,
						align: 'start',
						sortable: true,
						value: 'off_day_request_id',
					}
				],
			}
		},
		computed: {
			// users() {
			// 	return this.$store.getters.users_list
			// },
			// employees() {
			// 	return this.$store.getters.employees_list
			// },
			user() {
				return this.$store.getters.user
			}
		},
		mounted() {
			this.off_day_requests.employee_id = this.user.employee_id
			this.off_day_requests.user_id = this.user.user_id
			this.off_day_requests.off_day_request_status = 'pending'
                            // console.log(this.user)

			this.readOffDayRequests();
			this.readOffDayRequestsAll();
            this.readOffDayRequest();
		},
		methods: {
			addOffDayRequests() {
				this.loading_btn = true
				requests.createOffDayRequests(this.off_day_requests).then(r => {
					if (r.status == 200) {
						this.off_day_requests = {
							off_day_request_status: 'pending',
							employee_id: this.user.employee_id,
							user_id: this.user.user_id
						}
						this.rows.push(
							r.data.new_data
						)
						this.snackbar = {
							value: true,
							text: 'OffDayRequests Added',
							color: 'success'
						}
					} else {
						this.snackbar = {
							value: true,
							text: 'Fail to add OffDayRequests',
							color: 'error'
						}
					}
				})
					.finally(() => {
						this.loading_btn = false
					})

			},
			deleteOffDayRequests(off_day_request_id) {
				requests.deleteOffDayRequests(off_day_request_id).then(r => {
					this.delete_dialog = false
					if (r.status == 200) {
						this.rows = this.rows.filter(f => {
							return f.off_day_request_id != off_day_request_id
						})
						this.snackbar = {
							value: true,
							text: 'OffDayRequests Deleted',
							color: 'success'
						}
					} else {
						this.snackbar = {
							value: true,
							text: 'Delete Faild',
							color: 'error'
						}
					}
				})
			},

			deleteOffDayRequestsList() {
				let ids = this.selected_rows.map(m => m.off_day_request_id)
				requests.deleteOffDayRequestsList(ids).then(r => {
					if (r.status == 200) {
						this.rows = this.rows.filter(f => {
							return ids.indexOf(f.off_day_request_id) == -1
						})
						this.snackbar = {
							value: true,
							text: this.selected_rows.length + ' OffDayRequests Deleted',
							color: 'success'
						}
					} else {
						this.snackbar = {
							value: true,
							text: 'Delete Faild',
							color: 'error'
						}
					}
				})
			},

			readOffDayRequestsAll() {
				this.loading = true
				let columns = {
					off_day_request_status: 'viewing'
				}
				requests.reportRequests(columns).then(r => {
					if (r.status == 200) {
						this.rows = r.data.rows
						this.loading = false
					} else {
						this.snackbar = {
							value: true,
							text: 'Fail to read OffDayRequests',
							color: 'error'
						}
					}
				})
					.catch(e => {
						this.snackbar = {
							value: true,
							text: 'Fail to read OffDayRequests',
							color: 'error'
						}
					})
					.finally(() => {
						this.loading = false
					})
			},
            	readOffDayRequest() {
				this.loading = true
                // console.log('userssss')
                // console.log(this.user)
				let columns = {
					supervisor_id: this.user.position_id
				}

                   if(this.user.user_id == 17){
                    columns = {
                        supervisor_id: 19
                    }
                }
				requests.reportRequests(columns).then(r => {
					if (r.status == 200) {
						this.old_rows = r.data.rows
						this.loading = false
					} else {
						this.snackbar = {
							value: true,
							text: 'Fail to read OffDayRequests',
							color: 'error'
						}
					}
				})
					.catch(e => {
						this.snackbar = {
							value: true,
							text: 'Fail to read OffDayRequests',
							color: 'error'
						}
					})
					.finally(() => {
						this.loading = false
					})
			},
			readOffDayRequests() {
				this.loading = true
				let columns = {
					off_day_request_status: 'pending'
					,
					supervisor_id: this.user.position_id
				}
                if(this.user.user_id == 17){
                    columns = {
                        off_day_request_status: 'pending',
                        supervisor_id: 19
                    }
                }
				requests.reportRequests(columns).then(r => {
					if (r.status == 200) {
						this.rowsAll = r.data.rows
						this.loading = false
					} else {
						this.snackbar = {
							value: true,
							text: 'Fail to read OffDayRequests',
							color: 'error'
						}
					}
				})
					.catch(e => {
						this.snackbar = {
							value: true,
							text: 'Fail to read OffDayRequests',
							color: 'error'
						}
					})
					.finally(() => {
						this.loading = false
					})
			},
			selectOffDayRequests(i) {
				this.selected_off_day_requests = i
				this.delete_dialog = true
			},
		},
	}
</script>
                    